//import Vue from 'vue'
import {createRouter, createWebHistory} from 'vue-router'
import Home from '@/components/home'
import Login from '@/components/login'



//Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/logout',
		name: 'logout',
		component: () => import('@/components/logout')
	},
]
const router = createRouter({
    history: createWebHistory(),
	routes
})

export default router