<template>
  <div class="pages">
    <transition-group name="fruit-list">
      <button v-for="(layer, key) in layers_canvas" :key="key" @click="selectL(key)"
        :class="key == currentPage ? 'activePage' : ''" @mouseover="tooltipShow(key)" @mouseout="tooltipShow(null)">
        <span>{{ layer.number[0] }}</span>
        <span class="delimiter" v-if="layer.count_number == 2">|</span>
        <span v-if="layer.count_number == 2">{{ layer.number[1] }}</span>

        <div class="tooltip" v-if="key === tooltip">
          <p>
            {{
            layer.count_number == 1
            ? "Страница: " + layer.number[0]
            : "Страницы: " + layer.number[0] + ", " + layer.number[1]
            }}
          </p>
          <img class="toolImg" v-if="this.$parent.previewImages[key] != undefined" :src="getImage(key)" />
        </div>
      </button>
    </transition-group>
    <button @mouseover="addTool = true" @mouseout="addTool = false" @click="addLayer"
      v-if="maxLayers > layers_canvas.length">
      <span></span>
      <span class="delimiter">+</span>
      <span></span>

      <div class="tooltip" v-if="addTool">Добавить</div>
    </button>
    <button @mouseover="remTool = true" @mouseout="remTool = false" @click="removeLayer"
      v-if="minLayers <= layers_canvas.length">
      <span></span>
      <span class="delimiter">-</span>
      <span></span>

      <div class="tooltip" v-if="remTool">Удалить</div>
    </button>
    <button @mouseover="saveTool = true" @mouseout="saveTool = false" @click="preSavePDF">
      <span></span>
      <span class="delimiter">Сохранить</span>
      <span></span>

      <div class="tooltip" v-if="saveTool">Сохранить фотокнигу</div>
    </button>
  </div>

  <div class="shadow" v-if="popup">
    <div class="main">
      <div class="loginForm">
        <div>Сохранение</div>
        <button class="closeButton" @click="hide">✖</button>

        <label class="title" for="name">Имя</label><br />
        <input id="name" type="text" v-model="clname" /><br />

        <label class="title" for="mail">Почта</label><br />
        <input id="mail" type="email" v-model="clmail" /><br />

        <label class="title" for="desc">Описание</label><br />
        <input id="desc" type="text" v-model="cldesc" /><br />

        <!--<div v-if="owner">
          <label class="title" for="addL">Добавить поле</label><br />
          <input id="addL" type="text" class="menos" v-model="addL" />
          <button class="addButton" @click="addOption">+</button>
        </div>-->

        <label class="title">Доп. услуги</label><br />
        <select name="sheet" id="sel" class="sel" v-model="cldop">
          <option value="">Выберите из списка</option>
          <option v-for="op in options" :key="op.id" :value="op.name">
            {{ op.name }}
          </option>
        </select><br />

        <button class="siteButton" @click="savePDF">Отправить</button>
      </div>
    </div>
  </div>
</template>

<script>
import apiGetAdditionalServices from "../api/apiGetAdditionalServices";
import apiAddAdditionalServices from "../api/apiAddAdditionalServices";

export default {
  name: "Nav pages",
  data() {
    return {
      owner: true, // you`ll need add check is admin
      addL: "",
      clname: "",
      clmail: "",
      cldesc: "",
      cldop: "",
      options: [
        // { id: 1, name: "Подарочная коробка" },
        // { id: 2, name: "Подарочная лента" },
        // { id: 3, name: "Перезвоните мне" },
      ],
      layers_canvas: this.$store.getters.getLayers, // Данные для страниц
      minLayers: this.$store.getters.getCountLayers[0], // Минимальное число слоев
      maxLayers: this.$store.getters.getCountLayers[1], // Максимальное число слоев
      currentPage: 0, // Для отрисовки активной кнопки
      tooltip: null, // Временная переменная для отображения подсказки на кнопке при наведении
      addTool: false, // Подсказка для кнопки добавить страницу
      remTool: false, // Подсказка для кнопки добавить страницу
      saveTool: false, // Подсказка для кнопки добавить страницу
      popup: false,
    };
  },
  methods: {
    selectL(key) {
      // Выбор страницы
      this.$parent.setImageFromCanvas(this.currentPage); // Делаем мини превью для кнопок страниц
      this.currentPage = key;
      this.$parent.selectLayer(key); // Изменяем текущую страницу в Арр чтобы отрисовать другую область
    },
    getImage(key) {
      return this.$parent.previewImages[key];
    },
    tooltipShow(key) {
      this.tooltip = key;
      if (key !== null) this.$parent.setImageFromCanvas(this.currentPage); // Обновим превью текущей страницы
    },
    addLayer() {
      let layers = this.layers_canvas;
      if (layers.length > 0) {
        let last = layers.length - 1;
        let f = layers[last].number[1];
        this.$store.dispatch("addLayer", {
          first: ++f,
          second: ++f,
        });
      }
    },
    removeLayer() {
      // Check if active page / Если удалить активную страницу то будет падение, потому проверяем
      if (this.currentPage == this.layers_canvas.length - 1) {
        let cp = this.currentPage;
        this.$parent.selectLayer(--cp, cp);
      }

      this.$store.dispatch("removeLayer");
    },
    addOption() {
      apiAddAdditionalServices(this.addL);
      apiGetAdditionalServices().then((result) => {
        this.options = result;
      });
      this.addL = "";
    },
    hide() {
      this.popup = false;
    },
    preSavePDF() {
      this.popup = true;
      apiGetAdditionalServices().then((result) => {
        this.options = result;
      });
    },
    savePDF() {
      this.$parent.savePDF(this.clname, this.clmail, this.cldesc, this.cldop);
    },
  },
};
</script>

<style scoped>
.shadow {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 12 !important;
}

.main {
  /* popup*/
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: white;
  border: 1px solid #020cd2;
  border-radius: 4px;
  width: 280px;
  min-height: 140px;
  padding: 10px 0;
  z-index: 13 !important;
}

.shadow input[type="text"],
.shadow input[type="email"] {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #020cd2;
  box-sizing: border-box;
  outline: none !important;
  padding: 4px 6px 4px 8px;
  height: 30px;
  font-size: 12px;
}

.title {
  margin: 10px 0 6px 0;
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  color: #333;
}

.sel {
  width: 160px;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-image: url(https://dompechati45.ru/wp-content/themes/pechatdom/img/selectarrow.svg);
  background-position: calc(100% - 5px) center;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #020cd2;
  box-sizing: border-box;
  outline: none !important;
  padding: 4px 6px;
  margin: 2px 0 10px 0;
  height: 30px;
  font-size: 12px;
  color: #333;
  font-family: "Roboto";
}

.sel option[selected] {
  color: red;
}

.closeButton {
  position: absolute;
  right: 4px;
  top: 4px;
  border-color: #020cd2;
  background-color: #020cd2;
  cursor: pointer;
  letter-spacing: 0.4px;
  color: #fff;
  line-height: 20px;
  border-radius: 3px;
  margin: 0 0;
}

.closeButton:hover {
  box-shadow: 0 0 6px #020cd2;
}

.menos {
  width: 120px;
}

.addButton {
  border-color: #020cd2;
  background-color: #020cd2;
  cursor: pointer;
  letter-spacing: 0.4px;
  color: #fff;
  line-height: 20px;
  border-radius: 3px;
  margin: 0 0 0 4px;
}

.addButton:hover {
  box-shadow: 0 0 6px #020cd2;
}

.pages {
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
  z-index: 10;
  position: relative;
  justify-content: center;
}

.pages button {
  margin: 0 0 1px 0;
  cursor: pointer;
  font-family: "Roboto";
  font-size: 16px;
  color: white;
  text-decoration: none;
  text-align: center;
  line-height: 45px;
  outline: none;
  min-width: 70px;
  border: 0;
  background: linear-gradient(to bottom, #2830d9 50%, #020cd2 50%);
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.pages button:hover {
  background: linear-gradient(to bottom, red 50%, #ea0000 50%) !important;
}

.pages button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.pages button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.delimiter {
  padding: 0 4px 0 4px;
}

.tooltip {
  position: absolute;
  transform: translate(0, 4px);
  border-radius: 3px;
  width: auto;
  height: auto;
  background: linear-gradient(to bottom, red 50%, #ea0000 50%);
  padding: 0 8px;
}

.tooltip p {
  margin: 0;
}

.toolImg {
  width: 160px;
  height: 90px;
  border: 1px solid #020cd2;
}
</style>

<style>
.activePage {
  background: linear-gradient(to bottom, #ea0000 50%, #e00000 50%) !important;
}

.fruit-list-enter-active {
  /* add */
  transition: all 0.4s ease-out;
}

.fruit-list-leave-active {
  /* remove */
  transition: all 0.4s cubic-bezier(0.75, 0.8, 0.9, 0.63);
}

.fruit-list-enter-from,
.fruit-list-leave-to {
  transform: translateY(-80px);
  opacity: 0;
}
</style>
