import baseFetch from './baseFetch';


export default async function(formData, category, token_user) {
    token_user;
    const request = await baseFetch("api/compositions", {
        method: "GET"
    });
    return await request;
}
