export default {
    state: {
        categories: [
            {
                name: 'фото',
                filter: 'photo',
            },
            {
                name: 'украшения',
                filter: 'decorations',
            },
            {
                name: 'принты',
                filter: 'prints',
            },
            {
                name: 'рамки',
                filter: 'framework',
            },
            {
                name: 'фоны',
                filter: 'backgrounds',
            },
            {
                name: 'календарные сетки',
                filter: 'calendar grids',
            },
            {
                name: 'шаблоны стилей',
                filter: 'style templates',
            },
            {
                name: 'компоновки',
                filter: 'layout',
            },
        ]
    },
    mutations: [

    ],
    actions: {

    },
    getters: {
        getCategories (state) {
            return state.categories
        }
    }
}