import baseFetch from './baseFetch';


export default async function(formData) {
    const request = await baseFetch("api/email/sendPDF", {
        method: "POST",
        body: formData,
    });
    return await request;
}
