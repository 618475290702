export default {
    state: {
        layers: [
            {
                start: true,
                count_number: 1,
                number: [0],
            },
            {
                count_number: 2,
                number: [1, 2],
            },
            {
                count_number: 2,
                number: [3, 4],
            },
            {
                count_number: 2,
                number: [5, 6],
            },
        ],
        maxLayers: 20,
        minLayers: 4,
        maxPages: 4
    },
    mutations: {
        addLayer (state, payload) {
            state.layers.push(payload)
        },
        removeLayer (state) {
            state.layers.pop()
        },
        editPages (state, payload) {
            if (payload.increment) state.maxPages += 1
            else state.maxPages -= 1
        }
    },
    actions: {
        addLayer ({commit}, payload) {
            let arr = [payload.first]
            let pages = 1
            if (payload.second) {
                arr.push(payload.second)
                pages ++
            }
            
            commit('addLayer', {
                count_number: pages,
                number: arr,
            })
            commit('editPages', {
                increment: true
            })
        },
        removeLayer ({commit}) {
            commit('removeLayer')
            commit('editPages', {
                increment: false
            })
        },
    },
    getters: {
        getLayers (state) {
            return state.layers
        },
        getCountLayers (state) {
            return [state.minLayers, state.maxLayers]
        },
        getMaxPages (state) {
            return state.maxPages
        }
    }
}