<template>
  <div>
    <Header />
    <routerView />
  </div>
</template>

<script>

import Header from './components/header.vue'

export default {
  name: 'App',
  components: {
    Header
  },
}
</script>



<style>

@import url('https://fonts.googleapis.com/css?family=Mouse+Memoirs|Tangerine|Inconsolata|Droid+Sans|Mochiy+Pop+One|Roboto');

body {
  background: linear-gradient(180deg,#fdfeff 0%,#d3ddfc 100%);
  overflow: scroll;
  padding-bottom: 30px;
  font-family: Roboto;
  background-repeat: no-repeat;
}

.canvas-container {
  background: #fff;
  border: 1px solid #020cd2;
}

.content-category {
  display: flex;
  align-items: center;
  height: 152px;
  justify-content: flex-start;
}

.category-image {
  width: 128px;
  height: 128px;
  object-fit: cover;
}

.siteButton {
  border-color: #020cd2;
  background-color: #020cd2;
  cursor: pointer;
  letter-spacing: .4px;
  color: #fff;
  line-height:30px;
  border-radius: 3px;
  margin:0 2px;
}

.siteButton:hover {
  box-shadow: 0 0 6px #020cd2;

}

.card {
  display: flex;
  height: 145px;
  max-width: 1280px;
  overflow-x: auto;
  overflow-y:hidden;
}

.card--content {
  background-color: transparent;
  position: relative;
  display: inline-block;
  transition: all 1s;
}

.removeImg {
  position: absolute;
  font-size: 16px;
  top:1px;
  right: 1px;
  border:1px solid #EA0000;
  padding: 0 5px 2px 4px;
  border-radius: 3px;
  background: #020cd2;
  color:white;
  cursor: pointer;
}


.images-list-enter-active { /* add */
  transition: all 0.2s ease-out;
}

.images-list-leave-active { /* remove */
  transition: all 0.04s ease-out;
}

.images-list-enter-from,
.images-list-leave-to {
  transform: translateY(130px);
  opacity: 0;
}
.images-list-move {
  transition: transform 5s;
}

</style>
