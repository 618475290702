import baseFetch from './baseFetch';


export default async function(login, password) {
    const request = await baseFetch("api/login/", {
        method: "POST",
        body: JSON.stringify({
            login,
            password,
        }),
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
    });
    return await request;
}
