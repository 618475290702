<template>
    <div>
        <!-- TOP NAVIGATION PAGES -->
        <nav-pages ref="nav_pages"/>

        <template v-for="(layer, key) in layers_canvas" :key="key">
            <KeepAlive>
                <fabric-canvas :index="key" :w="canvas_size[0]" :h="canvas_size[1]" :field="canvas_size[2]"
                    ref="fabricCanvas" v-if="key === layer_activated" />
            </KeepAlive>
        </template>


        <div class="category-container">
            <!-- BUTTONS LAYERS -->
            <nav-buttons />
            <!-- IMAGES -->
            <div class="content-category">
                <button class="siteButton" v-if="category_select !== 'layout'" @click="triggerUpload" @change="onFileChange">
                    Добавить изображение
                    <input ref="fileInput" type="file" style="display: none;" accept="image/jpg, image/png" />
                </button>
                <button class="siteButton" @click="saveComposition"  v-if="category_select === 'layout'">Сохранить конпоновку</button>
                <section class="card">
                    <transition-group name="images-list"  v-if="category_select !== 'layout'">
                        <div class="card--content" v-for="(image, key) in getImagesFromCategory()" :key="key">
                            <div class="removeImg" title="Удалить изображение" @click="deleteImage(image.id)">✖</div>
                            <img :src="image.image" :alt="image.title" class="category-image"
                                v-on:click="addImageToCanvas(image.image)" />
                        </div>
                    </transition-group>
                </section>
                <section class="card" >
                    <transition-group name="images-list" v-if="category_select === 'layout'">

                        <div class="card--content" v-for="(compose, key) in compositions" :key="key">
                            <div class="removeImg" title="Удалить компоновку" @click="deleteComposition(compose.id)">✖</div>
                            <div title="Установить компоновку" 
                                style="cursor: pointer; width: 100px; height: 100px; margin-left: 20px;  border-radius: 10px; display: flex; background: white; justify-content: center;  align-items: center;"
                                @click="setComposition(compose.id)">
                                {{ compose.id }}
                            </div>
                        </div>

                    </transition-group>
                    
                </section>
            </div>
        </div>
    </div>
</template>

<script>
//import { fabric } from 'fabric'; // For save json data
import FabricCanvas from '../components/fabricCanvas.vue'
import NavButtons from '../components/navButtons.vue'
import NavPages from '../components/navPages.vue'
import jsPDF from 'jspdf'
import generator from 'generate-password'
import apiUpload from '../api/apiUpload'
import apiLoadImage from '../api/apiLoadImage'
import apiSaveComposition from '../api/apiSaveComposition'
import apiDeleteComposition from '../api/apiDeleteComposition'
import apiGetCompositions from '../api/apiGetCompositions'
import apiDataUrl from '../api/apiDataUrl'
import apiUploadPDF from '../api/apiUploadPDF';
// import { host } from '../api/baseFetch'

//let host = 'http://127.0.0.1:8000';
//const IMAGES = 'photobook_images'
const MAX_IMAGE_WIDTH = 1140 // px
const MAX_IMAGE_HEIGHT = 460
const PAGE_FIELD = 10
const WIDTH_EXPORT = 297 // mm
const HEIGHT_EXPORT = 210

//let fabric_canvas = null; // For save json data

export default {
    name: "Home",
    async mounted() {

        //fabric_canvas = new fabric.Canvas('canvas') // For save json data
        this.startedImagesData() // Init images object
        window.addEventListener('beforeunload', this.onClose)

        const list_image = await apiLoadImage();
        for(let image of list_image){
            const src = image['link'];
            const category = image['category'];
            apiDataUrl(`${src}`, (data) => {
                this.local_image_list[category].push({
                    image: data
                });
            })
        }
        apiGetCompositions().then(items => this.compositions = items)
    },
    data() {
        return {
            category_list: this.$store.getters.getCategories, // Категории
            category_select: null, // Выбранная категория
            //image_list: [],
            local_image_list: /*JSON.parse(localStorage.getItem(IMAGES)) ||*/ {},
            layers_canvas: this.$store.getters.getLayers, // Данные страниц
            layer_activated: 0, // Активная страница
            canvas_size: [MAX_IMAGE_WIDTH, MAX_IMAGE_HEIGHT, PAGE_FIELD], // Для canvas
            previewImages: [], // Мини превью (base64)
            compositions: [], // Мини превью (base64)
            ctxs: [] // Контексты для каждого canvas fabric будут присваиваться при выборе (не нашел как решить проблему смешивания)
        }
    },
    components: {
        FabricCanvas,
        NavButtons,
        NavPages
    },
    methods: {
        
        setImageFromCanvas: function (indexCanvas) { // Делается мини превью для подсказки к текущей странице
            this.previewImages[indexCanvas] =  this.compressImage(this.$refs.fabricCanvas[0].getImage(), 160, 90)
        },
        selectLayer(index, removedPage = -1) { // Смена страницы (-1 заглушка)
            if (removedPage != -1) {
                this.ctxs[removedPage] = null // Убираем контекст если страница удалена
                this.previewImages[removedPage] = null // Тоже самое с миниатюрами
            }
            else
                this.ctxs[this.layer_activated] = this.$refs.fabricCanvas[0].ctx() // Присвоение контекста со старой страницы

            this.layer_activated = index // Смена активной страницы
            if (this.ctxs[index])
                this.$refs.fabricCanvas[0].setCtx(this.ctxs[index]) // Если уже есть контекст для активной страницы то толкает его в фабрику
        },
        addImageToCanvas(src) {
            let toLastLayer = false // Если фон то нужно его поставить последним
            if(this.category_select == "backgrounds")
                toLastLayer = true;
            this.$refs.fabricCanvas[0].addImageToCanvas(src, toLastLayer)
        },
        addText() {
            this.$refs.fabricCanvas[0].addText()

        },
        triggerUpload() {

            this.$refs.fileInput.click() // Вызываем скрытый input file при клике на кнопку добавить изображение

        },
        deleteImage(title) {
            let images = this.local_image_list[this.category_select]
            this.local_image_list[this.category_select] = images.filter(img => img.title != title)
        },
        onFileChange(event) { // При выборе файла

            let selfObj = this; // current object
            let reader = new FileReader();
            let imgName = event.target.files[0].name; // Название изображения
            let type = event.target.files[0].type;

            
            const token_user = localStorage.getItem("token");
            if(token_user){
                const formData = new FormData();
                formData.append('file', event.target.files[0]);
                formData.append('token', token_user);
                apiUpload(formData, this.category_select);
            }

            reader.addEventListener("load", function () {
                if (this.result /*&& localStorage*/) {
                    let image = new Image()
                    image.onload = function () { // При загрузке изображения
                        let imageBase64 = selfObj.compressImage(this) // Будет проверка превышены ли размеры (можно делать сжатие)

                        let check = 1 // Проверка для того чтобы не загружать второе изображение с тем же именем
                        selfObj.local_image_list[selfObj.category_select].map(value => {
                            if (value.title == imgName)
                                check = 0
                        })

                        if (check == 1)
                            selfObj.local_image_list[selfObj.category_select].push({ title: imgName, image: imageBase64 }) // Добавляем изображение в объект взависимости от категории
                        else
                            alert("Изображение с таким именем " + imgName + " уже существует в группе: " + selfObj.category_select)
                        // selfObj.saveImages()
                    }                    
                    image.type = type
                    image.src = this.result // Записываем base64 в созданное изображение
                } else {
                    alert("Ошибка загрузки изображения");
                }
            })
            reader.readAsDataURL(event.target.files[0]) // Получаем base64 и входим в onload
            this.$refs.fileInput.value = null // Обнуляем fileinput

        },
        compressImage(image, maxWidth = MAX_IMAGE_WIDTH, maxHeight = MAX_IMAGE_HEIGHT, compress = 1) { // Сжатие и пропорциональное уменьшение изображения

            // Изначальные размеры
            let cwidth = image.width
            let cheight = image.height

            // Если обе стороны изображение превышены (Какая больше превышена по процентам по той и уменьшаем)
            if (image.width > maxWidth && image.height > maxHeight) {
                let lessPercentW = maxWidth / (image.width / 100)
                let lessPercentH = maxHeight / (image.height / 100)
                if (lessPercentW <= lessPercentH) {
                    cwidth = Math.round(image.width / 100 * lessPercentW)
                    cheight = Math.round(image.height / 100 * lessPercentW)

                } else {
                    cheight = Math.round(image.height / 100 * lessPercentH)
                    cwidth = Math.round(image.width / 100 * lessPercentH)
                }
            } else if (image.width > maxWidth) { // W
                // Из разницы размера уменьшения узнаём на сколько процентов уменьшится ширина (чтобы на столько же % потом уменьшить высоту)
                let lessPercent = maxWidth / (image.width / 100 /* Узнаём сколько приходится на 1 процент */)
                cheight = Math.round(image.height / 100 * lessPercent)
                cwidth = maxWidth
            } else if (image.height > maxHeight) { // H
                let lessPercent = maxHeight / (image.height / 100)
                cwidth = Math.round(image.width  / 100 * lessPercent)
                cheight = maxHeight
            } else return image.src // Если всё в норме дальше можно не заниматься ерундой и вернуть картинку

            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            canvas.width = cwidth
            canvas.height = cheight
            ctx.drawImage(image, 0, 0, cwidth, cheight)
            if(image.type == "image/png" || image.type == "image/gif") {
                let im = new Image()
                im.type = image.type
                im.src = canvas.toDataURL(image.type, compress)
                return im.src
            }
            else 
                return canvas.toDataURL('image/jpg', compress)

        },
        async savePDF(clname, clmail, cldesc, cldop) {
            /*
            /////// CLIENT DATA clname, clmail, cldesc, cldop
            */
            for (let u = 0; u < this.layers_canvas.length; u++) {
                if (this.ctxs[u] == null || this.ctxs[u] == undefined) {
                    alert("Невезможно сохранить. У вас заполнены не все страницы!")
                    return
                }
            }

            let doc = new jsPDF({
                orientation: "landscape",
                unit: "mm",
            })
            //doc.text("Hello World", 110, 110)
            for (let u = 0; u < this.layers_canvas.length; u++) {
                let img = new Image();
                img.src = this.ctxs[u].toDataURL('image/jpg')
                doc.addImage(img, 'JPEG', 0, 0, WIDTH_EXPORT, HEIGHT_EXPORT)
                if (u + 1 < this.layers_canvas.length)
                    doc.addPage('a4', 'landscape')
            }

            let toSaveDoc = doc.saveGraphicsState()
            let file = toSaveDoc.output('blob', { filename: 'Photo_' + generator.generate({ length: 12, numbers: true }) + '.pdf' })

            
            let formData = new FormData();
            formData.append('pdf', file)
            formData.append('name', clname)
            formData.append('mail', clmail)
            formData.append('description', cldesc)
            formData.append('extend', cldop)
            
            apiUploadPDF(formData);
            //toSaveDoc.save('Photo_' + generator.generate({ length: 12, numbers: true }) + '.pdf') // Скачать
        },
        startedImagesData(reset = false) { // Инициализация объекта изображений с категориями

            if (Object.keys(this.local_image_list).length === 0) { // Если объект без категорий (начальный)
                this.category_list.map((cat) => {
                    this.local_image_list[cat.filter] = [] // Добавление пустых (объектов изображений) по количеству категорий
                })
                //this.saveImages()
            } else if (reset) { // Удаление изображений
                this.local_image_list = {}
                //this.saveImages()
            }

        },
        getImagesFromCategory() { // Грузим объект изображений по определённой категории
            return this.local_image_list[this.category_select] || []
        },
        async setComposition(id) {           
            const pages = this.compositions.filter((item)=>item.id == id)[0].pages;
            // this.ctxs =  this.compositions.filter((item)=>item.id == id)[0].pages;
            for (let id =0; id <= pages.length -1; id++) {
                this.$refs.nav_pages.selectL(id);
                this.selectLayer(id);
            }

            this.$refs.nav_pages.selectL(0);
            this.selectLayer(0);

            for(let id_page in pages){

                await this.sleep(100);
                const page = pages[id_page];
                id_page = parseInt(id_page)              
                this.$refs.nav_pages.selectL(id_page);
                this.selectLayer(id_page);
                await this.sleep(100);
                await this.setCompositionImage(page);
                await this.sleep(100);
            }
        },

        setCompositionImage(page){
            const promise1 = new Promise((resolve) => {
                this.$refs.fabricCanvas[0].ctx().loadFromJSON(page, ()=>{
                    this.$refs.fabricCanvas[0].ctx().renderAll();
                    resolve(true);
                });
            });
            return promise1;
            
        },  

        async sleep(time){
            const promise1 = new Promise((resolve) => {
                setTimeout(() => {
                    resolve('foo');
                }, time);
            });
            return promise1;

        },
        async saveComposition() { // For save json data
            let fabric_canvas = this.ctxs[0];
            if (fabric_canvas !== undefined) {
                const token_user = localStorage.getItem("token");

                if (token_user) {
                    let list = []
                    // есть проблема, если на страницу нажали первый раз - она не создается в CTXS переменной. И в бд не добавляется соответственно
                    for (let key in this.ctxs) {
                        let item = this.ctxs[key].toJSON();
                        item.page = key; // в каждой записи будет лежать переменная page
                        list[key] = item;
                    }

                    const formData = new FormData();
                    formData.append('token', token_user);
                    // Отправляю строку, на беке она превращается в JSON обьект
                    formData.append('object', JSON.stringify(list));
                    await apiSaveComposition(formData);  
                    apiGetCompositions().then(items => this.compositions = items)

                } else {
                    console.log('Ты не пройдёшь!')
                }
            }
        },
        async deleteComposition(id) {
            
            const token_user = localStorage.getItem("token");
            await apiDeleteComposition(id, token_user);
            apiGetCompositions().then(items => this.compositions = items)
        },
        onClose(event) { // Очистка, изначально нужна была для хранилища

            console.log('unload ' + event);
            this.startedImagesData(true) // reset & save images object

        }

    }
}
</script>

<style scoped>
</style>