<template>
    <div class="main">
        <div class="loginForm">

            <div>Вход на сайт</div>

            <label class="title" for="login">Логин</label>
            <input id="login" type="text" v-model="login" />

            <label class="title" for="pass">Пароль</label>
            <input id="pass" type="password" v-model="password"/>

            <button :disabled="!valid" class="loginButton" @click="toLogin">Войти</button>
        </div>
    </div>
</template>

<script>
import apiLogin from '../api/apiLogin'

export default {
    name: "Login",
    data() {
        return {
            login: "",
            password: "",
            valid: false,
            /*loginRules: [
                value => !!value || 'Required.',
                value => (value || '').length <= 20 || 'Max 20 characters',
                value => (value || '').length >= 8 || 'Min 8 characters',
                value => {
                    const pattern = /^|(([a-zA-Z\-0-9]+))$/
                    return pattern.test(value) || 'Invalid login.'
                }
            ],
            passwordRules: [
                value => !!value || 'Password is required',
                value => (value && value.length >= 6) || 'Name must be less than 6 characters'
            ],*/

        }
    },
    methods: {
        async toLogin() {
            console.log("To Login", this.login, this.password);
            const request = await apiLogin(this.login, this.password);
            console.log("To Login", this.login, this.password);
            if(request.token){
                localStorage.setItem("token", request.token);
                this.$router.push("/");
            }
        },
        validate() {
            console.log("Valid", this.login, this.password)
            this.valid = true
        }
    },
    watch: {
        login: function () {
            this.validate()
        },
        password: function () {
            this.validate()
        }
    }
}
</script>

<style scoped>
.main {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-height: 800px;
}

.loginForm {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 40px 0 0 0;
    min-width: 280px;
    min-height: 180px;
    background-color: #fff;
    border: 1px solid #000;
    border: 1px solid #020cd2;
    border-radius: 4px;
    padding: 10px;
}

.loginForm input[type="text"] {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, .5);
    border: 1px solid #020cd2;
    box-sizing: border-box;
    outline: none !important;
    padding: 4px 6px 4px 8px;
    height: 30px;
    font-size: 12px;
}

.loginForm div:first-child {
    margin: 0 0 6px 0;
}

.title {
    margin: 10px 0 6px 0;
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    color: #333;
}

.loginButton {
    width: 100px;
    height: 32px;
    margin: 14px 0;
    border-color: #020cd2;
    background-color: #020cd2;
    cursor: pointer;
    color: #fff;
    border-radius: 3px;
}

.loginButton:disabled {
    background-color: #333;
}

.loginButton:disabled:hover {
    box-shadow: 0 0 0 #333;
    cursor: default;
}

.loginButton:hover {
    box-shadow: 0 0 6px #020cd2;

}
</style>