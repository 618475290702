import baseFetch from './baseFetch';


export default async function(formData, category, token_user) {
    token_user;
    const request = await baseFetch("api/upload_file/?category=" + category, {
        method: "POST",
        body: formData,
    });
    return await request;
}
