<template>
    <div class="header-category">
        <button v-on:click="selectCategory(category.filter)" v-for="(category, key) in category_list" :key="key"
            :class="category.filter == category_select ? 'activeButton' : ''">
            {{ category.name }}
        </button>

        <button v-on:click="addText">Добавить текст</button>
    </div>
</template>

<script>

export default {
    name: "nav buttons",
    data() {
        return {
            category_list: this.$store.getters.getCategories,
            category_select: null,
        }
    },
    methods: {
        async selectCategory(category) {

            this.category_select = category;
            this.$parent.category_select = category;
        },
        addText() {
            this.$parent.addText()
        }
    }
}

</script>

<style scoped>
.header-category button {
    margin: 2px 0 4px 0;
    cursor: pointer;
    font-family: "Roboto";
    font-size: 14px;
    border-right: 1px solid rgba(255, 255, 255, .3) !important;
    color: white;
    text-decoration: none;
    text-align: center;
    line-height: 45px;
    outline: none;
    min-width: 140px;
    border: 0;
    background: linear-gradient(to bottom, #2830d9 50%, #020cd2 50%);
}

.header-category button:hover {
    background: linear-gradient(to bottom, red 50%, #EA0000 50%) !important;
}

.header-category button:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.header-category button:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
</style>

<style>
.activeButton {
    background: linear-gradient(to bottom, #DA0000 50%, #D00000 50%) !important;
}
</style>