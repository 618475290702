<template>
    <div class="line_canvas">
        <div class="container-canvas" :style="cssProps">
            <canvas id="canvas" :width="w" :height="h"></canvas>

            <div v-if="editText" id="textControls" class="edit_text">
                <div class="elemContainer">
                    <label class="descrLabel">Шрифт</label><br />
                    <select class="sel" v-model="currentFont">
                        <option v-for="(f, key) in fonts" :key="key" :value="f">{{ f }}</option>
                    </select>
                </div>
                <div class="elemContainer fl">
                    <label class="descrLabel">Размер</label><br />
                    <input class="numb" v-model="size" type="number" min="0" max="200" />
                </div>
                <div class="elemContainer fl">
                    <label class="descrLabel">Цвет</label><br />
                    <input class="colInputHalf" type="color" v-model="color" />
                </div>
                <div class="clear"></div>
                <div class="elemContainer fl">
                    <label class="descrLabel">Фон</label><br />
                    <input class="colInputQuather fl" type="color" v-model="back" />
                    <div class="clearBack fl" @click="clearBackground">✖</div>
                    <div class="clear"></div>
                </div>
                <div class="elemContainer fl">
                    <label class="descrLabel">Обводка</label><br />
                    <input class="colInputQuather fl" type="color" v-model="stroke" />
                    <input class="numbQuather fl" v-model="strokeSize" type="number" min="0" max="8" />
                    <div class="clear"></div>
                </div>
                <div class="clear"></div>
                <div class="elemContainer">
                    <label class="descrLabel descrLabelHalf fl">Жирный</label>
                    <input type="checkbox" class="fl" v-model="bold" />
                    <div class="clear"></div>
                </div>
                <div class="elemContainer">
                    <label class="descrLabel descrLabelHalf fl">Наклонный</label>
                    <input type="checkbox" class="fl" v-model="italic" />
                    <div class="clear"></div>
                </div>
            </div>

            <div id="scaleControls" class="edit_scale">
                <div class="seekbar elemContainer">
                    <label class="descrLabel">Масштаб</label><br />
                    <input class="fl" type="range" v-model="zoom" @mouseup="applyRender" min="100" max="300" />
                    <div class="scButton fl">{{ zoom }}%</div>
                    <div class="clear"></div>
                </div>

                <div class="elemContainer">
                    <label class="descrLabel">Фон</label><br />
                    <input class="colInput" type="color" v-model="background" @blur="applyRender" />
                </div>

                <div class="elemContainer">
                    <label class="descrLabel">Бумага</label><br />
                    <select name="sheet" id="sel" class="sel">
                        <option value="Мелованная глянцевая">Мелованная глянцевая</option>
                        <option value="Мелованная матовая">Мелованная матовая</option>
                        <option value="Офсетная ('Снегурочка')">Офсетная ('Снегурочка')</option>
                        <option value="Картон односторонний">Картон односторонний</option>
                        <option value="Картон двусторонний">Картон двусторонний</option>
                        <option value="Картон с тиснением 'лен'">Картон с тиснением 'лен'</option>
                        <option value="Самоклейкая бумага">Самоклейкая бумага</option>
                        <option value="Этикеточная">Этикеточная</option>
                        <option value="Color Copy">Color Copy</option>
                    </select>
                </div>


            </div>
            <!-- Lines -->
            <div v-for="(line, key) in helps.filter(h => h.cl == 'h')" :key="key" class="horizontalLine"
                :style="'width:' + line.ln + 'px;' + 'top:' + line.ofy + 'px;' + 'left:' + line.ofx + 'px;'"></div>
            <div v-for="(line, key) in helps.filter(v => v.cl == 'v')" :key="key" class="verticalLine"
                :style="'height:' + line.ln + 'px;' + 'top:' + line.ofy + 'px;' + 'left:' + line.ofx + 'px;'"></div>
            <!-- Bottom titles -->
            <div class="pageInfo" :style="'left:' + w / 4 + 'px;'">
                {{ layer.start ? 'Задняя сторона' : (layer.number[0] == 1 ? 'форзац' : 'Страница: ' + layer.number[0])
                }}
            </div>
            <div class="pageInfo" :style="'left:' + w / 4 * 3 + 'px;'">
                {{ layer.start ? 'Передняя сторона' : (maxPages - 1 == index ? 'форзац' : 'Страница: ' +
                        layer.number[1])
                }}
            </div>
            <!-- Forzac covers -->
            <div class="covers" :style="'left:' + (w / 4 + (field / 2)) + 'px;'" v-if="layer.number[0] == 1"></div>
            <div class="covers" :style="'left:' + ((w / 4 * 3) - (field / 2)) + 'px;'" v-if="maxPages - 1 == index"></div>
        </div>
    </div>
</template>
<script>
import { fabric } from 'fabric';

let fabric_canvas = null;

export default {
    name: 'fabricCanvas',
    props: ['index', 'w', 'h', 'field'],
    data() {
        return {
            editText: false, // v-show окна редактирования теста
            size: 80, // Размер текущего текста
            strokeSize: 1, // Размер обводки текущего текста
            color: '#111111', // Цвет текущего текста
            back: '#ffffff', // Фон текущего текста
            stroke: '#сссссс', // Обводка текущего текста
            bold: false, // Жирность текущего текста
            italic: false, // Наклон текущего текста
            focusedText: null, // Объект текущего текста на который был фокус
            background: '#ffffff',
            currentFont: 'Arial',
            zoom: 100,
            fonts: [
                'Arial',
                'Verdana',
                'Tangerine',
                'Droid Sans',
                'Inconsolata',
                'Mouse Memoirs',
                'Mochiy Pop One'
            ],
            helps: [
                { cl: 'h', ofx: this.field, ofy: this.field, ln: this.w - this.field * 2 }, // Класс, смещение, длина
                { cl: 'h', ofx: this.field, ofy: this.h - this.field, ln: this.w - this.field * 2 },
                { cl: 'v', ofx: this.field, ofy: this.field, ln: this.h - this.field * 2 },
                { cl: 'v', ofx: this.w / 2, ofy: this.field, ln: this.h - this.field * 2 },
                { cl: 'v', ofx: this.w - this.field, ofy: this.field, ln: this.h - this.field * 2 },
            ]
        }
    },
    computed: {
        maxPages() {
            return this.$store.getters.getMaxPages
        },
        layer() {
            return this.$store.getters.getLayers[this.index]
        },
        cssProps() {
            return {
                '--half-width': (this.w / 2 - this.field) + 'px',
                '--half-height': (this.h - this.field * 2) + 'px',
                '--top-field': this.field + 'px',
            }
        }
    },
    mounted() {
        fabric_canvas = new fabric.Canvas('canvas')
        let selfObj = this
        window.addEventListener('keydown', (key) => {
            if (key.code === 'Delete') {
                this.deleteActiveElement();
            }
        });
        fabric.util.addListener(fabric_canvas.upperCanvasEl, 'click', function (e) { // Добавляем слушателя клика
            if (fabric_canvas.findTarget(e)) {
                selfObj.focusedText = fabric_canvas.findTarget(e)
                let objType = fabric_canvas.findTarget(e).type;
                if (objType === 'i-text') { // Проверяем  является ли элемент текстом
                    // init
                    selfObj.size = selfObj.focusedText.fontSize || 80
                    selfObj.strokeSize = selfObj.focusedText.strokeWidth || 1
                    selfObj.color = selfObj.focusedText.fill || '#111111'
                    selfObj.stroke = selfObj.focusedText.stroke || '#cccccc'
                    selfObj.back = selfObj.focusedText.textBackgroundColor || '#ffffff'
                    selfObj.bold = selfObj.focusedText.fontWeight == 'bold' ? true : false
                    selfObj.italic = selfObj.focusedText.fontStyle == 'italic' ? true : false
                    selfObj.currentFont = selfObj.focusedText.fontFamily || 'Arial'
                    selfObj.showTextEdit(true) // Отображение окна редактирования текста
                }
            }
        });
        fabric_canvas.on('before:selection:cleared', function (e) { // Выход из фокуса текста
            if (e.target.type === 'i-text') {
                selfObj.showTextEdit(false) // Скрытие окна редактирования текста
            }
        });
        fabric_canvas.on('mouse:wheel', function (opt) { // Масштаб колесом мыши
            let delta = opt.e.deltaY
            let zoom = fabric_canvas.getZoom()
            zoom *= 0.999 ** delta
            if (zoom > 3) zoom = 3
            if (zoom < 1) zoom = 1
            selfObj.zoom = Number.parseInt(zoom * 100)
            fabric_canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
            opt.e.preventDefault()
            opt.e.stopPropagation()
        });

        fabric_canvas.setBackgroundColor(this.background, fabric_canvas.renderAll.bind(fabric_canvas))
    },
    methods: {
        addImageToCanvas(url, toBack) { // toBack - нужно затолкнуть последним (фон)
            let addedObjects = fabric_canvas.getObjects()
            if (toBack && addedObjects.length != 0) // Если пришел фон и в канвасе есть объекты то вставляем текущий назад
                fabric.Image.fromURL(url, (myImg) => {
                    fabric_canvas.insertAt(myImg, 0, false);
                })
            else
                fabric.Image.fromURL(url, (myImg) => {
                    fabric_canvas.add(myImg);
                });
        },
        addText() {
            let fabric_text = new fabric.IText('Some text', {
                fontFamily: this.currentFont,
                color: '#000000',
                fontSize: 80,
                left: 300,
                top: 170,
            });
            fabric_canvas.add(fabric_text)

        },
        deleteActiveElement() {
            if (!fabric_canvas.getActiveObject()) return;

            if (fabric_canvas.getActiveObject()._objects) {
                fabric_canvas.getActiveObject()._objects.forEach((obj) => {
                    fabric_canvas.remove(obj);
                })
            } else {
                fabric_canvas.remove(fabric_canvas.getActiveObject());
            }
        },
        showTextEdit(visible) { // Редактор текста
            this.editText = visible
        },
        renderTextElement(property, value) { // Изменение свойства и перерисовка (только для текста)
            let txt = this.focusedText.get('text')
            this.focusedText.set('text', '')
            this.focusedText.set(property, value)
            this.focusedText.set('text', txt)
            if (fabric_canvas !== null) fabric_canvas.renderAll()
        },
        getImage() { // Скачивание готового изображения
            let img = new Image();
            img.src = fabric_canvas.toDataURL('image/png')
            return img
        },
        applyRender() { // Красим фон при удалении фокуса
            fabric_canvas.setBackgroundColor(this.background)
            fabric_canvas.zoomToPoint({ x: this.w / 2, y: this.h / 2 }, this.zoom / 100);
            // fabric_canvas.renderCanvas(fabric_canvas.getSelectionContext(), fabric_canvas.getActiveObjects())
            fabric_canvas.renderAll()
        },
        ctx() {
            return fabric_canvas
        },
        setCtx(ctx) {
            fabric_canvas = ctx
            // update
            //this.maxPages = this.$store.getters.getMaxPages
        },
        clearBackground() {
            this.back = ''
            //this.renderTextElement('textBackgroundColor', 'transparent')
        }
    },
    watch: {
        size: function (val) {
            this.renderTextElement('fontSize', val < 0 ? 0 : val)
        },
        color: function (val) {
            this.renderTextElement('fill', val)
        },
        back: function (val) {
            this.renderTextElement('textBackgroundColor', val)
        },
        bold: function (val) {
            this.renderTextElement('fontWeight', val ? 'bold' : 'normal')
        },
        italic: function (val) {
            this.renderTextElement('fontStyle', val ? 'italic' : 'normal')
        },
        stroke: function (val) {
            this.renderTextElement('stroke', val)
        },
        strokeSize: function (val) {
            this.renderTextElement('strokeWidth', val < 0 ? 0 : val)
        },
        currentFont: function (val) {
            this.renderTextElement('fontFamily', val < 0 ? 0 : val)
        },
    }
}
</script>


<style scoped>
/*.line_canvas {
    position: relative;
}*/


.line_canvas {
    display: flex;
    justify-content: center;
    padding: 20px;
    position: relative;
}

.container-canvas {
    position: relative;
    z-index: 2;
}

.container-canvas input[type="color"] {
    border: 1px solid #020cd2;
    height: 30px;
    background-position: calc(100% - 5px) center;
    border-radius: 4px;
}

.container-canvas input[type="number"] {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, .5);
    border: 1px solid #020cd2;
    box-sizing: border-box;
    outline: none !important;
    padding: 4px 0 4px 2px;
    height: 30px;
    font-size: 12px;
}

.edit_scale {
    position: absolute;
    top: 0;
    left: -191px;
    width: 170px;
    background-color: #fff;
    border-top: 1px solid #020cd2;
    border-left: 1px solid #020cd2;
    border-bottom: 1px solid #020cd2;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 10px;
}

.elemContainer {
    margin: 4px 0 8px 5px;
}

.seekbar input[type="range"] {
    width: 120px;
    background-color: transparent;
}

.seekbar input[type="range"]:hover {
    background-color: #EA0000;
}

.scButton {
    width: 24px;
    height: 24px;
}

.edit_text {
    position: absolute;
    top: 0;
    right: -198px;
    width: 175px;
    background-color: #fff;
    border-top: 1px solid #020cd2;
    border-right: 1px solid #020cd2;
    border-bottom: 1px solid #020cd2;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 10px;
}


.sel {
    width: 160px;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    background-image: url(https://dompechati45.ru/wp-content/themes/pechatdom/img/selectarrow.svg);
    background-position: calc(100% - 5px) center;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, .5);
    border: 1px solid #020cd2;
    box-sizing: border-box;
    outline: none !important;
    padding: 4px 6px;
    margin: 2px 0 0 0;
    height: 30px;
    font-size: 12px;
    color: #333;
    font-family: 'Roboto';
}

.sel option[selected] {
    color: red;
}

.numb {
    width: 78px;
    margin: 2px 0 0 0;
}

.numbQuather {
    width: 38px;
    margin: 2px 0 0 2px;
}

.colInput {
    width: 160px;
}

.colInputHalf {
    width: 78px;
    margin: 2px 0 0 0;
}

.colInputQuather {
    width: 39px;
    margin: 2px 0 0 0;
}

.descrLabel {
    margin-bottom: 6px;
    font-size: 14px;
}

.descrLabelHalf {
    width: 78px;
}

.edit_text input[type="checkbox"] {
    width: 18px;
    height: 18px;
    margin: 0 0 6px 6px;
    border: 1px solid #020cd2;
    color: #020cd2;
    border-radius: 4px;
}

.fl {
    float: left;
}

.clearBack {
    font-weight: bold;
    font-size: 20px;
    border: 1px solid #020cd2;
    border-radius: 4px;
    padding: 0 10px 1px 10px;
    margin: 2px 0 0 2px;
    cursor: pointer;
}

.horizontalLine {
    position: absolute;
    border-top: 1px solid red;
    z-index: 11;
    -webkit-box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.42);
    -moz-box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.42);
    box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.42);
}

.verticalLine {
    position: absolute;
    border-left: 1px solid red;
    z-index: 11;
    -webkit-box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.42);
    -moz-box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.42);
    box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.42);
}

.pageInfo {
    position: absolute;
    display: inline-block;
    bottom: -18px;
    font-size: 12px;
    transform: translate(-50%, 0);
    background: white;
    padding: 2px 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 1px solid #020cd2;
    border-bottom: 1px solid #020cd2;
    border-right: 1px solid #020cd2;
    cursor: default;
}

.covers {
    position: absolute;
    transform: translate(-50%, 0);
    background: repeating-linear-gradient(-60deg, #555 0, #555 1px, transparent 1px, transparent 5px);
    width: var(--half-width);
    height: var(--half-height);
    top: var(--top-field);
    pointer-events: none;
}

.clear {
    clear: both;
}
</style>