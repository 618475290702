// import Vue from 'vue'
import Vuex from 'vuex'
import categories from './categories'
import layersCanvas from './layersCanvas'

//Vue.use(Vuex)

export default new Vuex.Store({
	state: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
        categories, layersCanvas
	}
})