import baseFetch from './baseFetch';


export default async function(name) {
    const request = await baseFetch("api/additional_service", {
        method: "POST",
        body: JSON.stringify({
            'token': localStorage.token,
            name,
        }),
        headers: {
            "Content-Type": "application/json",
        },
    });
    return await request;
}
