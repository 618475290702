<template>
    <header>
        <div class="container">
            <div class="row">
                <div class="logo_container"><a href="https://dompechati45.ru" class="logo"><img
                            src="https://dompechati45.ru/wp-content/uploads/2022/05/logo.png" alt="Дом печати"></a>
                </div>
                <div class="col-xs-4 hidden-sm hidden-md hidden-lg">
                    <div class="mobmenu"><span></span><span></span><span></span></div>
                </div>
                <div class="mobhiddenFirst mobhidden">
                    <div class="headinfo"> <a class="hiblock" href="javascript:void(0);"><img
                                src="https://dompechati45.ru/wp-content/uploads/2022/05/hicon1.png"
                                alt="Низкие цены"><span>Низкие цены</span></a> <a class="hiblock"
                            href="javascript:void(0);"><img
                                src="https://dompechati45.ru/wp-content/uploads/2022/05/hicon2.png"
                                alt="Доставка в регионы"><span>Доставка в регионы</span></a> <a class="hiblock"
                            href="/request"><noscript><img
                                    src="https://dompechati45.ru/wp-content/uploads/2022/05/hicon3.png"
                                    alt="Онлайн заявка"></noscript><img class=" ls-is-cached lazyloaded"
                                src="https://dompechati45.ru/wp-content/uploads/2022/05/hicon3.png"
                                data-src="https://dompechati45.ru/wp-content/uploads/2022/05/hicon3.png"
                                alt="Онлайн заявка"><span>Онлайн заявка</span></a></div>
                </div>
                <div class="mobhiddenLast mobhidden">
                    <div class="links firstLink"><a href="tel:89195909836"><noscript><img
                                    src="https://dompechati45.ru/wp-content/themes/pechatdom/img/tel1.svg"
                                    alt="headerlink"></noscript><img class=" ls-is-cached lazyloaded"
                                src="https://dompechati45.ru/wp-content/themes/pechatdom/img/tel1.svg"
                                data-src="https://dompechati45.ru/wp-content/themes/pechatdom/img/tel1.svg"
                                alt="headerlink">8 (919) 590-98-36</a></div>
                    <div class="links lastLink"> <a href="tel:83525363402"><noscript><img
                                    src="https://dompechati45.ru/wp-content/themes/pechatdom/img/tel2.svg"
                                    alt="headerlink"></noscript><img class=" ls-is-cached lazyloaded"
                                src="https://dompechati45.ru/wp-content/themes/pechatdom/img/tel2.svg"
                                data-src="https://dompechati45.ru/wp-content/themes/pechatdom/img/tel2.svg"
                                alt="headerlink">8 (35253) 6-34-02</a> <a href="mailto:shdp45@mail.ru"><noscript><img
                                    src="https://dompechati45.ru/wp-content/themes/pechatdom/img/mail.svg"
                                    alt="headerlink"></noscript><img class=" ls-is-cached lazyloaded"
                                src="https://dompechati45.ru/wp-content/themes/pechatdom/img/mail.svg"
                                data-src="https://dompechati45.ru/wp-content/themes/pechatdom/img/mail.svg"
                                alt="headerlink">shdp45@mail.ru</a></div>
                </div>
                <div class="col-xs-3 hidden-sm hidden-md hidden-lg">
                    <div class="menubtn"><span></span><span></span><span></span></div>
                </div>
            </div>
            <nav>
                <div class="menu-%d0%bc%d0%b5%d0%bd%d1%8e-%d1%88%d0%b0%d0%bf%d0%ba%d0%b0-container">
                    <ul id="menu-%d0%bc%d0%b5%d0%bd%d1%8e-%d1%88%d0%b0%d0%bf%d0%ba%d0%b0" class="menu">
                        <li id="menu-item-41"
                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-41"><a
                                href="https://dompechati45.ru/about/">О компании</a></li>
                        <li id="menu-item-44"
                            class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-44"><a
                                href="https://dompechati45.ru/polygraphy/">Полиграфия</a></li>
                        <li id="menu-item-47"
                            class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-45 current_page_item menu-item-47">
                            <a href="https://dompechati45.ru/request/" aria-current="page">Онлайн заявка</a></li>
                        <li id="menu-item-43"
                            class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-43"><a
                                href="https://dompechati45.ru/ourworks/">Наши работы</a></li>
                        <li id="menu-item-42"
                            class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-42"><a
                                href="https://dompechati45.ru/promotions/">Акции</a></li>
                        <li id="menu-item-40"
                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-40"><a
                                href="https://dompechati45.ru/contacts/">Контакты</a></li>
                    </ul>
                </div>
            </nav>
        </div>
    </header>
</template>

<script>
export default {
    name: 'header',
    data () {
        return {
            
        }
    }
}
</script>

<style scoped>

header {
    padding: 14px 0 0 0;
    height: 160px;
    overflow: hidden;
}
.container {
    width: 1140px;
    margin:0 auto;
    padding-right: 15px;
    padding-left: 15px;
}
.logo_container {
    width: 23%;
    padding: 0 0 0 14px;
}
header .row {
    align-items: flex-end;
}
.row {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
}
header .headinfo {
    display: flex;
    justify-content: center;
}
header .headinfo .hiblock {
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin: 0 0;
    background: #fff;
    box-shadow: -2px -2px 5px rgb(0 0 0 / 2%), 2px 2px 5px rgb(0 0 0 / 2%);
    border-radius: 4px;
    height: 80px;
    width: 100%;
    text-decoration: none !important;
    color: #333;
}
header .headinfo .hiblock img {
    align-self: center;
    margin-bottom: 4px;
    line-height: 28px;
}

img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
}

.mobhidden {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.mobhiddenFirst {
    width: 30.8%;
    padding: 4px 0 0 24px;
}
.mobhiddenLast {
    width: 39%;
    padding: 0 5px 0 30px;
}
header .headinfo .hiblock span {
    text-align: center;
    font-size: 12px;
    letter-spacing: -.2px;
}
header .links {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
}

.firstLink {
    width: 50%;
}
.lastLink {
    width: 40%;
}
header .links a:last-child {
    margin-bottom: 0;
}

header .links a {
    display: block;
    margin-bottom: 6px;
    color: #333;
    font-size: 18px;
}
a {
    text-decoration: none;
}
header .links a img {
    margin-right: 6px;
}

/*** NAV */
header, nav {
    display: block;
}

header nav ul {
    margin: 10px 0 0;
    padding: 0 0;
    display: flex;
    align-items: center;
    list-style: none;
    height: 60px;
    background: linear-gradient(to bottom,#2830d9 50%,#020cd2 50%);
    border-radius: 4px;
    overflow: hidden;
}

header nav ul li {
    width: 100%;
    height: 100%;
    line-height: 60px;
    border-right: 1px solid rgba(255,255,255,.3);
    text-align: center;
}

header nav ul li a {
    display: block;
    color: #fff;
    font-size: 20px;
    text-decoration: none !important;
    transition: .2s;
    height: 60px;
}
header nav ul li a:hover {
    background:linear-gradient(to bottom,#ff2727 50%,red 50%)
}

*, *:hover, *:focus, *:active {
    outline: none !important;
}
</style>