import baseFetch from './baseFetch';


export default async function(id, token_user) {
    token_user;
    const request = await baseFetch("api/compositions/"+id, {
        method: "DELETE",
        body: {
            token: token_user
        },
        headers: {
            "Content-Type": "application/json",
        },
    });
    return await request;
}
